import React from 'react';
import CustomBreadcrumb from '../components/CustomBreadcrumb';
import Layout from '../components/Layout';
import Seo from '../components/Seo';

const LostAndDamagedSuccessPage = ({ pageContext, location }) => {
	const {
		breadcrumb: { crumbs }
	} = pageContext;

	return (
		<Layout>
			<Seo
				title="Loss & Damage Form"
				description="Report lost or damaged hear.com hearing aids. Complete our easy form for quick assistance and solutions. Reliable support for your hear.com hearing aid needs."
				noindex={true}
			/>
			<div className="o-wrapper">
				<div className="o-row-4">
					<img
						className="o-fluid-img"
						src="https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/loss-and-damage-hero.jpg"
						alt="woman holding hearing aids"
					/>
				</div>
			</div>

			<div className="o-wrapper o-wrapper--small u-font-serif">
				<div className="o-row-4p5"></div>
				<div className="o-row-2p5">
					<CustomBreadcrumb location={location} crumbs={crumbs} />
				</div>
				<div className="o-row-3">
					<p className="c-loss-damage-success-title">
						Thank you for submitting your loss and damage claim for your hearing aids.
					</p>
				</div>
				<div className="o-row-3">
					<p className="c-md-p">
						We understand how important your hearing aids are to you, and we're here to assist you in every possible
						way.
					</p>
				</div>

				<div className="o-row-3">
					<div className="c-loss-damage-success-box">
						<div style={{ maxWidth: '440px' }}>
							<p className="c-loss-damage-success-special">
								Our dedicated service team is currently <span className="u-color-brand">reviewing your claim</span>.
							</p>
						</div>
					</div>
				</div>
				<div className="o-row-3">
					<p className="c-md-p">
						We may reach out to you for additional information or to discuss the details of your payment. Please expect
						a call from us within the next 3 days.
					</p>
				</div>
				<div className="o-row-3">
					<p className="c-md-p">
						We appreciate your patience and understanding as we work to resolve this matter promptly.
					</p>
				</div>
				<div className="o-row-3">
					<p className="c-md-p">
						Thank you for choosing us for your hearing aid needs. We are committed to providing you with the best
						service possible.
					</p>
				</div>
				<div className="o-row-5">
					<p className="c-md-p">
						Sincerely,
						<br /> Your hear.com service team
					</p>
				</div>
			</div>
		</Layout>
	);
};

export default LostAndDamagedSuccessPage;
